







































































import { Component, Vue } from 'vue-property-decorator';
import { ElForm } from 'element-ui/types/form';
import { getTenantData } from '@/serve/medicalAppointment/institutionManage';
import {
  getType2List,
  delType2,
} from '@/serve/professional/index';
interface Data {
  current?: number;
  size?: number;
  [propName: string]: any;
}
interface Detail {
  [propName: string]: any;
}
@Component({
  name: 'MedicalReport',
})
export default class MedicalReport extends Vue {
  private visible = false
  public currentPage4 = 1;
  public form = {
    // checkerName: '',
    current: 1,
    size: 10,
    // checkerIdCard: '',
    // checkStartTime: '',
    // checkEndTime: '',
    // tenantId: '',
  };
  public tableData = [];
  public created() {
    this.getReportList();
  }
  // 获取数据接口
  public async getReportList() {
    // 如果form的数据有'' 则赋值为null 由于后端也会查询''类型的值
    for (const key in this.form) {
      if ((this.form as Data)[key] === '') {
        (this.form as Data)[key] = null;
      }
    }
    const res = await getType2List(this.form);
    this.tableData = await res.data;
  }
  private deleteOne(id: number){
    delType2(id).then(res=>{
      if(res.code===200){
        this.$message.success("操作成功！")
        this.getReportList()
      }
    })
  }
  // 点击左右箭头
  public handleSizeChange(val: any): void {
    this.form.size = val;
    this.getReportList();
  }
  // 点击页码
  public handleCurrentChange(val: any): void {
    this.form.current = val;
    this.getReportList();
  }
}
